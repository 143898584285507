import React, { useContext } from "react";
import { SiteContext } from "gatsby-frontend/src/Site";
import { SingleContext } from "gatsby-frontend/src/Page";
import {
	TitleBlog,
	Image,
	FieldsDisplay,
	Text,
	Blocks,
	List,
} from "gatsby-frontend/plugins/gatsby-frontend-material";
import { Box, CircularProgress, Paper, Container, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function ProjectBody() {
	const theme = useTheme();
	const singleContext = useContext(SingleContext);
	const siteContext = useContext(SiteContext);

	const {
		attributes,
		logoPrint,
		hasFieldsDisplay,
		fieldsDisplay,
		title,
		hasDates,
		dates,
		dateFormat,
		image,
		vCard,
		isReversed,
		title_append,
		shareButtons,
		hasMainSection,
		hasBody,
		relationships,
		hasBodyMedia,
		media,
	} = singleContext;

	// console.warn('singleContext', singleContext);
	// console.warn('siteContext', siteContext);
	console.warn("hasMainSection", hasMainSection);

	if (!attributes) {
		return (
			<Box sx={{ textAlign: "center" }}>
				<CircularProgress sx={{ margin: "2rem auto" }} />
			</Box>
		);
	}

	return (
		<main>
			<Box
				className="print-only"
				sx={{ display: "none", "& img": { width: 200, height: "auto" } }}
			>
				<img src={logoPrint} alt="logo" />
			</Box>
		
			<Container
				maxWidth={theme?.customOptions?.containerMaxWidth || "xl"}
				sx={{ padding: theme.spacing(3, 0) }}
				className="main-container"
			>
				<Box className="main-wrapper">
					<Grid container className="main-grid-container no-sidebar" spacing={0}>
						<Grid className="main-grid-left" item xs={12}>
							<Box
								className="body-box"
								component={Paper}
								elevation={3}
								sx={{
									padding: theme.spacing(3, 4),
								}}
							>
								<Grid container spacing={4}>
									<Grid item sm={6}>

                  <TitleBlog
                      title={title}
                      title_append={title_append}
                      subtitle={attributes.field_subtitle}
                      hasDates={hasDates}
                      dates={dates}
                      dateFormat={dateFormat}
                      image={image}
                      vCard={vCard}
                      isReversed={isReversed}
                      shareButtons={shareButtons}
                      
                    />

										{hasBody && (
											<Text
												html={
													attributes.body.transformed
														? attributes.body.transformed
														: attributes.body.value
												}
											/>
										)}
										{hasMainSection && (
											<Blocks
												data={relationships.field_section_main.data}
												className="main-section"
											/>
										)}
										{hasBodyMedia && (
											<List
												{...media}
												useElastic={false}
												cols={2}
												layout="topbar"
												sx={{ marginTop: theme.spacing(3) }}
											/>
										)}
									</Grid>
									<Grid item sm={6}>
										{hasFieldsDisplay && fieldsDisplay.prepend && (
											<Box
												className="fixed-fields-display prepend"
												sx={{ marginBottom: theme.spacing(2) }}
											>
												<FieldsDisplay {...fieldsDisplay.prepend} />
											</Box>
										)}
										<Box
											className="fixed-featured-image"
											sx={{ marginBottom: theme.spacing(3) }}
										>
											<Image {...image} />
										</Box>
									</Grid>
								</Grid>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</main>
	);
}

export default ProjectBody;
