import React from 'react';
import { SingleWrapper } from 'gatsby-frontend/plugins/gatsby-frontend-material';
import ProjectBody from './ProjectBody';

function Project() {
  return (
    <SingleWrapper>
			<ProjectBody />
		</SingleWrapper>
  )
}

export default Project